import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {lighten} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import {sortBy} from 'lodash';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {parse} from 'query-string';
import React, {Fragment, useState, useEffect, useMemo} from 'react';
import {withRouter} from 'react-router-dom';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import Typography from '../../fhg/components/Typography';
import {cacheDelete} from '../../fhg/utils/DataUtil';
import AreaEdit from './AreaEdit';
import {MASTER_AREA_QUERY} from './AreaView';
import EquipmentEdit from './EquipmentEdit';
import QuestionDetailEdit from './QuestionDetailEdit';

const useStyles = makeStyles(theme => ({
   contentStyle: {
      height: '100%',
      flex: '1 1',
      minWidth: 410,
      overflow: 'hidden'
   },
   dividerStyle: {
      backgroundColor: theme.palette.secondary.main,
      height: '50%',
      margin: 'auto',
   },
   newQuestionStyle: {
      position: 'sticky',
      bottom: -theme.spacing(1),
   },
   leftSpace: {
      marginLeft: theme.spacing(1),
   },
   largeLeftSpace: {
      marginLeft: theme.spacing(2),
   },
   autoWidth: {
      width: 'auto',
   },
   scrollStyle: {
      overflow: 'auto',
   },
   deleteColorStyle: {
      color: theme.palette.error.main,
      '&:hover': {
         backgroundColor: lighten(theme.palette.error.light, 0.8),
      }
   },
}));

const QUESTION_SHORT_FRAGMENT = gql`
   fragment questionShortInfo on Question {
      id
      text
      isDeleted
      questionTypeId
      masterEquipmentId
      masterEquipment {
         id
         isDeleted
         name
      }
   }
`;

export const FILTER_QUESTIONS_QUERY = gql`
   query getFilteredQuestions ($id: Int!, $type: [Int], $area: [String], $standard: [String], $equipment: [String], $searchText: [String]) {
      area:area_ById(areaId: $id) {
         id
         name
         isDeleted
         equipment {
            id
            isDeleted
            value:id
            label:name
            modelId
            manufacturerId
            questions {
               id
               isDeleted
               text
               questionTypeId
            }
         }
      }
      questions:question_AllFiltered (questionSearch: {questionTypeId: $type, area: $area, standard: $standard, equipment: $equipment, text: $searchText}) {
         ...questionShortInfo
      }
   }
   ${QUESTION_SHORT_FRAGMENT}
`;

export const DELETE_AREA = gql`
   mutation deleteArea($id: Int!)
   {
      area_Delete(areaId: $id)
   }
`;

const sortByEquipment = item => item.masterEquipmentId;

/**
 * Component to display Area information and edit master questions list.
 *
 * Reviewed: 9/24/19
 */
function Area({location, match, area, onDelete}) {
   const [addedQuestion, setAddedQuestion] = useState(false);
   const [editedQuestion, setEditedQuestion] = useState(false);
   const [validateQuestion, setValidateQuestion] = useState(false);
   const [showEdit, setShowEdit] = useState(false);
   const [showEditEquipment, setShowEditEquipment] = useState(false);
   const [expanded, setExpanded] = useState();
   const [isDeleting, setIsDeleting] = useState(false);
   const classes = useStyles();
   const id = Number(get(match, 'params.id', 0));
   const filters = parse(location.search, {parseNumbers: true, parseBooleans: true});
   filters.type = filters.type === 0 ? undefined : filters.type;
   const skip = !id || !area;
   const variables = {id, area: get(area, 'name'), ...filters};

   const {loading, data, statusComponent} = useQueryFHG(FILTER_QUESTIONS_QUERY, {variables, skip, fetchPolicy: 'cache-and-network'});
   const [deleteArea, deleteAreaStatusComponent] = useMutationFHG(DELETE_AREA);

   useEffect(() => {
      document.addEventListener('keydown', handleKey, false);
      return () => {
         document.removeEventListener('keydown', handleKey, false);
      }
// eslint-disable-next-line
   }, []);

   /**
    * Sort the equipment by the equipment ID.
    */
   const sortedQuestions = useMemo(() => sortBy(get(data, 'questions', []), sortByEquipment), [data]);

   /**
    * Stop editing added questions on Escape.
    * @param event
    */
   const handleKey = (event) => {
      if (!event.defaultPrevented) {
         if (event.key === 'Escape') {
            event.stopPropagation();
            event.preventDefault();
            if (addedQuestion) {
               setAddedQuestion(false);
            }
         }
      }
   };

   /**
    * Add a new question. Show the edit panel for the new question.
    */
   const addQuestion = () => {
      setAddedQuestion(Date.now());
      setExpanded(-1);
      setEditedQuestion(-1);
   };

   /**
    * When a Question has been submitted or deleted . Close the expanded panel and hide the new question edit.
    */
   const handleReset = () => {
      setAddedQuestion(false);
      setExpanded(undefined);
      setValidateQuestion(undefined);
      setEditedQuestion(undefined);
   };

   /**
    * Handle expanding the panel.
    * @param panel The question ID of the panel or -1 for the new question panel.
    * @return {Function} The callback for the expanding.
    */
   const handleExpand = panel => (event, newExpanded) => {
      if (!editedQuestion) {
         setExpanded(newExpanded ? panel : false);
      } else {
         setValidateQuestion(editedQuestion);
      }
   };

   /**
    * Show the Area edit dialog.
    */
   const openEdit = () => {
      setShowEdit(true);
   };

   /**
    * Close the Area edit dialog.
    */
   const closeEdit = () => {
      setShowEdit(false);
   };

   /**
    * Show the Equipment edit dialog.
    */
   const openEditEquipment = () => {
      setShowEditEquipment(true);
   };

   /**
    * Show the Equipment edit dialog.
    */
   const closeEditEquipment = () => {
      setShowEditEquipment(false);
   };

   /**
    * When the user deletes the Area, show the DeleteMutation which will handle the confirmation and delete operation.
    */
   const handleDeleteArea = async () => {
      try {
         await deleteArea({
            variables: {id},
            optimisticResponse: {area_Delete: 1},
            update: cacheDelete([{query: MASTER_AREA_QUERY, queryPath: 'areas'}], id),
         });
         onDelete?.();
      } catch (e) {
         //Intentionally left blank
      }
   };

   /**
    * When a Question has been edited, mark it.
    */
   const handleChange = (event, id) => {
      setEditedQuestion(id);
      setValidateQuestion(undefined);
   };

   if (loading && statusComponent) {
      return statusComponent;
   }

   return get(data, 'area.id') !== undefined ? (
      <Grid container direction={'column'} wrap={'nowrap'} className={classes.contentStyle}
            spacing={1}>
         {statusComponent || deleteAreaStatusComponent}
         <AreaEdit open={showEdit} onClose={closeEdit} isCreate={false} area={get(data, 'area')}/>
         {showEditEquipment && (
            <EquipmentEdit open={showEditEquipment} onClose={closeEditEquipment} area={get(data, 'area')}/>
         )}
         <Grid container item direction={'row'} justify={'space-between'} wrap={'nowrap'}>
            <Grid item>
               <Typography variant={'h6'} color={'inherit'}>
                  {get(data, 'area.name')}
               </Typography>
            </Grid>
            <Grid item container spacing={2} direction={'row'} className={classes.autoWidth}>
               <Button onClick={openEditEquipment} color={'secondary'} className={classes.leftSpace}>
                  <Typography color='inherit' id={'area.editEquipment.button'}/>
               </Button>
               <Divider orientation={'vertical'} className={classes.dividerStyle}/>
               <Button onClick={openEdit} color={'secondary'} className={classes.leftSpace}>
                  <Typography color='inherit' id={'area.editSection.button'}/>
               </Button>
               <Divider orientation={'vertical'} className={classes.dividerStyle}/>
               <ConfirmButton
                  className={classes.leftSpace}
                  onConfirm={handleDeleteArea}
                  buttonLabelKey='area.deleteArea.button'
                  // style={{padding: 6}}
                  color='secondary'
                  titleKey='area.delete.title'
                  messageKey='area.delete.confirmation'
                  values={data?.area || {}}
                  confirmProps={{submitColorStyle: classes.deleteColorStyle}}
               />
            </Grid>
         </Grid>
         <Grid item className={classes.scrollStyle}>
            {sortedQuestions.map((question, index) =>  (
                  <Fragment key={`editQuestions${question.id}`}>
                     {((index === 0) || (sortedQuestions[index - 1].masterEquipmentId !== question.masterEquipmentId)) && (
                        <Typography variant={'subtitle1'} >
                           {get(question, 'masterEquipment.name', '')}
                        </Typography>)}
                     <QuestionDetailEdit
                        expanded={expanded === question.id}
                        validate={validateQuestion === question.id}
                        onExpand={handleExpand(question.id)}
                        area={get(data, 'area')}
                        equipmentList={get(data, 'area.equipment')}
                        question={question}
                        onSuccess={handleReset}
                        onChange={handleChange}
                        onDelete={handleReset}
                        onCancel={handleReset}
                     />
                  </Fragment>
               )
            )}
            <QuestionDetailEdit
               key={addedQuestion}
               show={addedQuestion}
               expanded={expanded === -1}
               validate={validateQuestion === -1}
               onExpand={handleExpand(-1)}
               area={get(data, 'area')}
               equipmentList={get(data, 'area.equipment')}
               onChange={handleChange}
               onSuccess={handleReset}
               onDelete={handleReset}
               onCancel={handleReset}
            />
         </Grid>
         <Grid item>
            <Button onClick={addQuestion} color={'primary'} className={classes.newQuestionStyle}>
               <Typography color='inherit' id={'area.newQuestion.button'}/>
            </Button>
         </Grid>
      </Grid>
   ) : (
      <Typography variant={'h6'} color={'inherit'} id={'area.noSelection.message'}/>
   );
}

Area.propTypes = {
   areas: PropTypes.array,                   // All areas in the master template.
   onDelete: PropTypes.func,                 //Called when a Area is deleted.
};

export default withRouter(Area);
